h1 {
    font-family: 'TheSans-LP5Plain', sans-serif;
    font-size: 32px;
}

body {
    font-family: "TheSans-LP5Plain", sans-serif;
}

input {
    font-family: "TheSans-LP5Plain", sans-serif;
}

cx-searchbox label input {
    font-family: 'TheSans-LP5Plain', sans-serif;
}

cx-login a {
    font-family: 'TheSans-LP5Plain', sans-serif;
}

.btn, button {
    font-family: 'TheSans-LP5Plain', sans-serif;
}

label .label-content {
    font-family: "TheSans-LP5Plain", sans-serif;
}