.ProductDetailsPageTemplate cx-page-slot {
    .quantity,
    cx-add-to-wishlist,
    cx-product-intro .rating,
    cx-product-summary .price,
    cx-product-references {
        display: none;
    }

    cx-product-intro {
        .code {
            font-family: 'TheSans-LP5Plain', sans-serif;
            font-size: 20px;
            color: var(--cx-color-primary);
        }
    }

    cx-product-summary {
        .summary {
            font-family: 'TheSans-LP5Plain', sans-serif;
            font-size: 26px;
            color: var(--cx-color-primary);
        }
    }


    &.Summary {
        cx-product-variants {
            @media (min-width: 992px) {
                grid-column: 1;
            }
        }
    }

    cx-tab-paragraph-container > div {
        background-color: var(--cx-color-tabs-bg);

         @media (min-width: 768px) {
            border-top-color: var(--cx-color-border-global);
         }
    }

    cx-tab-paragraph-container > button {
        font-size: 16px;
        text-transform: uppercase;
    }

    cx-add-to-cart {
        .btn {
            display: none;
        }
    }
}
