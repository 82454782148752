@mixin fontface($name, $weight: normal, $style: normal) {
    @font-face {
        font-family: "#{$name}";
        src: url('/assets/fonts/TheSans_Web/#{$name}.otf');
        font-style: $style;
        font-weight: $weight;
    }
}

@include fontface(TheSans-LP9BlackIT);
@include fontface(TheSans-LP9Black);
@include fontface(TheSans-LP8ExBldIT);
@include fontface(TheSans-LP8ExBld);
@include fontface(TheSans-LP7BldIT);
@include fontface(TheSans-LP7Bld);
@include fontface(TheSans-LP6SeBldIT);
@include fontface(TheSans-LP6SeBld);
@include fontface(TheSans-LP5PlainIT);
@include fontface(TheSans-LP5Plain);
@include fontface(TheSans-LP4SeLigIT);
@include fontface(TheSans-LP4SeLig);
@include fontface(TheSans-LP3LigIT);
@include fontface(TheSans-LP3Lig);
@include fontface(TheSans-LP2ExLigIT);

