/* You can add global styles to this file, and also import other style files */

/* $skipComponentStyles: (cx-carousel); */
@import '@spartacus/styles/index';
@import 'styles/index';
@import 'src/scss/00_Variables';
@import 'src/scss/01_MediaQueries';
@import 'src/scss/02_Mixins';
@import 'src/scss/03_briefing-category-images';
@import 'src/scss/04_Utilities';
@import 'src/scss/05_forms';
@import 'src/scss/modal';


:root {
  --cx-color-inverse: #00233e;
  --cx-color-dark: #ffffff;
  --cx-color-border-global: #b0bcce;
  --cx-color-secondary: #afbcce;
  --cx-color-primary: #00233e;
  --cx-color-visual-focus: transparent;
  --cx-color-background: #b0bcce;
  --cx-color-background-rgb: 176,188,206;
  --cx-color-tabs-bg: #FCE4DA;
}

cx-page-layout.ProductListPageTemplate, cx-page-layout.ProductGridPageTemplate,
cx-page-layout cx-page-slot{
  max-width: 100% !important;
  //PDP icin-Start
  cx-pagination {
    a {
      color: var(--cx-color-inverse);
      background-color: var(--cx-color-secondary);
    }

    a.current {
      color: var(--cx-color-dark);
    }
  }

  cx-product-list {
    cx-sorting {
      ng-select {
        .ng-select-container {
          background-color: var(--cx-color-secondary) !important;
        }

        .ng-option-marked {
          background-color: var(--cx-color-secondary) !important;
        }
      }
    }
  }
}
cx-page-layout.AccountPageTemplate {
  padding: 0!important;
  cx-page-slot {
    padding: 0!important;
  }
}
@media screen and (max-width: 768px) {
  body {
    &.freeze {
      overflow-y: hidden;
    }
    .content-wrapper {
      overflow-x: hidden;
      .inner {
        .carousel-panel {
          .slides {
            display: block!important;
            .slide {
              &.active {
                .item {
                  &.active {
                    width: 50% !important;
                    a {
                      .highlights-section {
                        .highlight-box {
                          max-width: none!important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .indicators {
          display: flex !important;
        }
      }
    }
  }
}
.content-wrapper {
  .inner {
    max-width: 1534px;
    margin: 0 auto;
    padding: 0 4rem;
    cx-carousel {
      .carousel-panel {
        .slides {
          display: block!important;
          .slide {
            &.active {
              .item {
                &.active {
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
      .next{
        &:disabled {
          display:none;
        }
        &:not(:disabled){
          display:none;
        }
      }
      .previous{
        &:disabled {
          display:none;
        }
        &:not(:disabled){
          display:none;
        }
      }
    }
  }
}
.npn-slider {
  .slider {
    .bar {
      background: $light-grey!important;
      box-shadow: unset!important;
      height: 7px!important;
      border-radius: 0!important;
      & > span.left-handle {
        width: 27px!important;
        height: 27px!important;
        top: -9px!important;
        background: $doehler-blue!important;
        border: 2px solid $doehler-blue!important;
        box-shadow: 0.1em 0.1em 0.3em rgb(0 0 0 / 30%);
        .handle-tooltip {
          top: -31px!important;
          left: 0!important;
          border: 1px solid $doehler-blue!important;
          background: $light-grey!important;
          color: $doehler-blue!important;
          &:before {
            border: 5px solid transparent!important;
            border-top-color: $doehler-blue!important;
            left: 20%!important;
          }
          &:after {
            left: 20%!important;
          }
        }
      }
      & > span.right-handle {
        width: 27px!important;
        height: 27px!important;
        top: -9px!important;
        background: $doehler-blue!important;
        border: 2px solid $doehler-blue!important;
        box-shadow: -0.1em 0.1em 0.3em rgb(0 0 0 / 30%);
        .handle-tooltip {
          top: -31px!important;
          left: 0!important;
          border: 1px solid $doehler-blue!important;
          background: $light-grey!important;
          color: $doehler-blue!important;
          &:before {
            border: 5px solid transparent!important;
            border-top-color: $doehler-blue!important;
            left: 20%!important;
          }
          &:after {
            left: 20%!important;
          }
        }
      }
      .filler {
        border: none!important;
        .step-indicators {
        }
        span {
          height: 65%;
          background: #B9C5D5!important;
        }
      }
    }
    .values {
      width: 106%!important;
      margin-top: 10px!important;
    }
  }
}

cx-page-layout{
  width: 100%;
  background-color: $white;
  //background-image: linear-gradient(to bottom, $light-blue, $white);
  background-position: top;
  background-size: 100% 47.5rem;
  background-repeat: no-repeat;
  /**padding-top: 0px;
  margin-top: 0px;**/
  position: relative;

  cx-page-slot{
    display: block;
    //max-width: 2000px!important;
    margin: 0 auto;
  }
}

header{
  display: none;
}
//PDP icin END


// // ************************************************** //
// // ************************************************** //

//CategoryPage START

//ProductList colour coating alanlarının sayfanın ortasında olması icin defaultaki 1140 px degisitildi.
%ProductListPageTemplate,
%ProductGridPageTemplate {
  max-width: 2000px;
  display: block!important;
}

@media screen and (max-width: 769px) {
  .ng-select,
  .ng-select.ng-select-single {
    .ng-select-container {
      font-size: 1rem!important;
      line-height: 1.3125rem!important;
      .ng-value-container {
        font-family: "TheSans", Arial, sans-serif;
        color: #9598A1;
        font-size: .875rem;
        line-height: 1rem;
        padding: 0;
        .ng-value {
          &:before {
            height: 18px!important;
            background-image: url("/assets/icons/chevron-down-filters.svg")!important;
          }
        }
      }
    }
    .ng-arrow-wrapper {
      display: none;
    }
  }
}
//CategoryPage Sorting
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: none!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.ng-select.ng-select-single.ng-pristine.ng-valid.ng-select-bottom.ng-touched {
  outline-style: none!important;
  outline-color: transparent!important;
  outline-width: 0!important;
  outline-offset: 0!important;
}
.ng-select,
.ng-select.ng-select-single {
  &.ng-select-bottom {
    border-bottom: 0!important;
    border: none;
    box-shadow: none;
  }
  &.ng-select-focused {
    outline-style: none!important;
    outline-color: transparent!important;
    outline-width: 0!important;
    outline-offset: 0!important;
  }
  .ng-select-container {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    border-radius: inherit;
    font-size: 1rem!important;
    line-height: 1.3125rem!important;
    font-family: 'TheSans', Arial, sans-serif;
    color: #476687;
    .ng-value-container {
      padding: 0 0 0 3px;
      .ng-value {
        &:before {
          content: "";
          position: relative;
          height: 22px;
          width: 18px;
          float: right;
          font-family: "TheSans", Arial, sans-serif;
          color: #4A4C50;
          background-image: url("/assets/icons/chevron-down-sorting.svg");
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 50%;
          margin-left: .5rem;
        }
      }
    }
  }
  .ng-arrow-wrapper {
    display: none;
  }
}
/*
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  appearance: none;
  border: none;
  outline: none;
  width: 100%;
  height: 55px;
  padding-left: 0.8rem;
  background: #F4F4F4;
  border-top: 1px solid #F4F4F4;
  border-bottom: 1px solid #AAAAAA;
  color: #01203d;
  font-family: inherit;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: none;
}*/

//CategorPage END





// // ************************************************** //
// // ************************************************** //

@font-face {
  font-family: TheSans;
  src: url('~src/assets/fonts/TheSans_LT_300.eot?') format("eot"),
  url('~src/assets/fonts/TheSans_LT_300.woff') format("woff"),
  url('~src/assets/fonts/TheSans_LT_300.woff2') format("woff2"),
  url('~src/assets/fonts/TheSans_LT_300.ttf') format("truetype")
}

@font-face {
  font-family: TheSansBold;
  src: url('~src/assets/fonts/TheSans_LP7_Bold.eot?') format("eot"),
  url('~src/assets/fonts/TheSans_LP7_Bold.woff') format("woff"),
  url('~src/assets/fonts/TheSans_LP7_Bold.woff2') format("woff2"),
  url('~src/assets/fonts/TheSans_LP7_Bold.ttf') format("truetype")
}

* {
  box-sizing: border-box;
  font-family: TheSans, Arial, sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  outline: none;
}

strong  {
  font-family: $headline-font;
  font-weight: 400;
}

html {
  width: 100%;
  scroll-behavior: smooth;
  height: auto;
  //overflow: hidden;
}

body {
  background-color: $bg-color;
  font-size: $font-size;
  line-height: $line-height;
  color: $doehler-blue;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;


  #shadow {
    @include transition(opacity .3s ease-in-out);
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;

    &.show {
      opacity: .7;
    }

    @include l {
      display: none;
    }
  }
}

.inner {
  @include s {
    padding: 0 1rem;
  }
}

li.product-benefits {
  p {
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    position: relative;
    font-size: 1rem;
    line-height: 1.3125rem;
    margin: 0!important;
    a {
      color: #fff;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      margin-top: .2rem;
      left: 0.4rem;
      width: 20px;
      height: 20px;
      background-image: url(~src/assets/icons/check-mark.svg);
    }
  }
}
a {
  cursor: pointer;
  color: $doehler-blue;
  font-family: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: TheSansBold,Arial,sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}


input[type="checkbox"] {
  display: none;
}

button {
  @include transition(color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out);
  font-size: $font-size-big;
  border: 1px solid transparent;
  color: $button-text-color;
  cursor: pointer;
  background-color: $button-bg-color;
  padding: 10px 20px;

  &:hover {
    background-color: $white;
    /* border-color: $doehler-blue; */
    color: $doehler-blue;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;

  color: $doehler-blue;
  cursor: pointer;
  font-size: inherit;
  padding: 0 7px;
  margin: 0 15px 0 0;
  width: auto;

  option {
    color: $doehler-blue;
    font-size: $font-size-normal;
  }
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

.d-select-wrapper {
  position: relative;
  z-index: 2;
  max-width: 230px;

  &.opened {
    label.select-label:after {
      transform: translateY(-50%) rotate(180deg);
    }

    #langSelect {
      display: block;
      opacity: 1;
    }
  }

  label {
    color: $doehler-blue;
    cursor: pointer;
    display: inline-block;
    margin-right: 7px;
    position: relative;
    z-index: 1;

    &.select-label:after {
      @include transition(transform .2s ease-in-out, top .2s ease-in-out);
      background-image: url(~src/assets/icons/chevron-down.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: $font-size-normal;
      width: $font-size-normal;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }

    &.select-value {
      padding-right: 30px;
      padding-left: 5px;
    }
  }

  #langSelect {
    display: none;
    list-style: none;
    text-align: left;
    padding: 1rem;
    border: 1px solid grey;
    background-color: white;
    position: absolute;
    top: 37px;
    right:27px;

    li {
      color: $doehler-blue;
      cursor: pointer;

      &:focus, &:hover {
        text-decoration: underline;
      }

      &:before {
        background-image: url(~src/assets/icons/chevron-right.svg);
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: $font-size-normal;
        width: $font-size-normal;
        display: inline-block;
        position: relative;
        vertical-align: middle;
      }
    }
  }
}

.button-container {
  @include flex-box($wrap: nowrap);

  button[type="reset"] {
    @include transition(background-color .3s ease-in-out, color .3s ease-in-out);
    background-color: $white;
    border: 1px solid $doehler-blue;
    color: $doehler-blue;

    &:hover {
      background-color: $doehler-blue;
      color: $white;
    }
  }

  .delete-btn {
    @include transition(background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out);
    background-color: orangered;
    border: 1px solid transparent;
    color: $white;

    fa-icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: $white;
      border-color: orangered;
      color: orangered;
    }
  }

  /*  button:disabled,
    button[disabled] {
      opacity: .5;
      pointer-events: none;
    }*/
}

/* INTEGRATION FROM SOCIAL MEDIA NEWS VIA FLOCKLER - CUSTOM STYLING */
.socialMediaNewsContainer .flockler-grid-item__profile__name {
  color:$doehler-blue;
  font-size: 1.2rem;
}

.socialMediaNewsContainer .flockler-grid-item__profile__username {
  color: $grey;
}

.d-login fa-icon > svg, button fa-icon > svg {
  margin-bottom: -2px;
}

/* PLP PAGINATION */
.slp-cx-pagination {
  a.start, .page, .end {
    text-decoration: none!important;
    color: $doehler-blue!important;
    background-color: transparent!important;
    border: none!important;
    &.page.disabled.current {
      background-color: #EBEBEB!important;
    }
  }
}