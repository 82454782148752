cx-page-slot {
    cx-breadcrumb {
        background-color: rgba(var(--cx-color-background-rgb), 0.4);

        nav span {
            color: var(--cx-color-primary);
            font-family: 'TheSans-LP5Plain', sans-serif;
            font-size: 14px;
        }
    }
}
