/* MODAL STYLES
-------------------------------*/
#sendRequestModal {
  .request-popup-modal {
    height: 2070px;
    .more-information-modal {
      margin-top: 20rem!important;
    }
  }
}

jw-modal {
  /* modals are hidden by default */
  display: none;
  .request-popup-modal {
    height: 2070px;
  }
  .jw-modal {
    /* modal container fixed across whole screen */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* z-index must be higher than .jw-modal-background */
    z-index: 1100;
    
    /* enables scrolling for tall modals */
    display: flex;


      /* width */
      ::-webkit-scrollbar {
        width: 12px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: $very-light-grey;
        border-radius: 0!important;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $light-grey;
        border-radius: 0 12px 12px 0;
        border-top: 12px solid $very-light-grey;
        border-bottom: 12px solid $very-light-grey;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: $grey; 
      }
      .jw-modal-body {
         padding: 2rem 0;
         background: #fff;
         align-self: center;
         border-radius: .75rem;

         /* margin exposes part of the modal background */
         margin: 0 auto;
         max-width: 730px;
         width: 100%;

         .jw-modal-inner {
           padding: 0 2.75rem;
           max-height: 2000px;
           overflow-y: hidden;

           @include s {
             padding: 1.5rem;
           }
         }

         .jw-modal-close-icon {

           position: relative;

           fa-icon {
             position: absolute;
             top: 12px;
             right: 15px;
             font-size: 1.4rem;
             cursor: pointer;
             color: $doehler-blue;

             &:hover {
               color: $mid-blue;
             }
           }
         }
       }
  }

  .jw-modal-background {
      /* modal background fixed across whole screen */
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      /* semi-transparent black  */
      background-color: #000;
      opacity: 0.75;
      
      /* z-index must be below .jw-modal and above everything else  */
      z-index: 1000;
  }
}

/*body.jw-modal-open {
  !* body overflow is hidden to hide main scrollbar when modal window is open *!
  //overflow: hidden;

  .content-wrapper, header {
    padding-right: 1rem;
  }
}*/

/* Overwrite max-width for specific form modals */
.veryWideModal .jw-modal .jw-modal-body {
  max-width: 1200px;
}
.wideModal .jw-modal .jw-modal-body {
  max-width: 730px;
}
.mediumWideModal .jw-modal .jw-modal-body {
  max-width: 800px;
}
.narrowModal .jw-modal .jw-modal-body {
  max-width: 550px;
}

#full-registration-form-modal .jw-modal-body, #contact-form-modal .jw-modal-body, #briefing-form-modal .jw-modal-body {
  max-width: 950px;
}
#edit-requirements .jw-modal-body {
  max-width: 875px;
}
#create-dspace-group-form-modal .jw-modal-body, #ask-an-expert-form-modal .jw-modal-body {
  max-width: 800px;
}
#project-onboarding-modal .jw-modal-body {
  max-width: 650px;
}
#edit-color .jw-modal-body,
#edit-taste .jw-modal-body,
#edit-filling .jw-modal-body,
#edit-ph .jw-modal-body,
#edit-chracteristics .jw-modal-body,
#edit-key-statement  .jw-modal-body {
  max-width: 550px;
}