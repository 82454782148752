header {
  border-bottom: 1px solid rgba(var(--cx-color-background-rgb), 0.4);

  .SiteContext,
  .SiteLinks,
  .MiniCart {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .SiteLogo {
      width: 80px;
      margin-bottom: 3px;
    }

    &.is-expanded {
      &:after {
        background-color: transparent;
      }
    }
  }

  .hamburger {
    &.is-active {
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: var(--cx-color-primary);
      }
    }
  }

  .hamburger-box {
    height: 20px;
    width: 28px;

    .hamburger-inner {
      background-color: var(--cx-color-primary);
      margin-top: 2px;
      &:after,
      &:before {
        background-color: var(--cx-color-primary);
      }
    }
  }
}

cx-category-navigation cx-navigation-ui {
  nav {
    font-family: 'TheSans-LP5Plain', sans-serif;
  }
}
