///////////////////////////////////////////
// *** MediaQuery Specific VARIABLES *** //
///////////////////////////////////////////

$bp-small: 48rem; // 768px
$bp-medium: 64rem; // 1024px
$bp-large: 85.375rem; // 1366px
$bp-xlarge: 120rem; // 1920px
$bp-xxlarge: 160rem; // 2560px

$mq-small: '(max-width: #{$bp-small})';
$mq-medium: '(min-width: #{$bp-medium})';
$mq-large: '(min-width: #{$bp-large})';
$mq-xlarge: '(min-width: #{$bp-xlarge})';
$mq-xxlarge: '(min-width: #{$bp-xxlarge})';

$font: 'TheSans-LP5Plain', sans-serif;
$headline-font: 'TheSans-LP5Plain', sans-serif;
$base-font-size: 1.125rem;
$base-line-height: 1.375rem;

$font-size-tiny: .75rem;
$font-size-small: .875rem;
$font-size-normal: 1rem;
$font-size-big: 1.25rem;
$font-size-xtra-big: 1.5rem;
$font-size-large: 1.75rem;
$font-size-xtra-large: 2rem;
$font-size-xxl: 2.5rem;
$font-size-extrem: 4rem;

$font-size: $base-font-size;
$line-height: $base-line-height;

//////////////////////////////
// *** COMMON VARIABLES *** //
//////////////////////////////

$doehler-blue: #00233e;
$doehler-green: #7fb741;
$doehler-bright-blue: #0073cc;
$mid-blue: #456687;
$alt-blue: #D7DDE6;
$light-blue: #B9C5D5;
$very-light-blue: #DEF;
$grey: #AAAAAA;
$light-grey: #EBEBEB;
$alt-grey: #ECEFF3;
$very-light-grey: #F4F4F4;
$white: #FFFFFF;
$black: #121212;
$text-color: $doehler-blue;
$bg-color: $white;
$box-shadow-color: rgba(0, 0, 0, .75);
$box-overlay-color: rgba(255, 255, 255, .8);
$button-bg-color: $doehler-blue;
$button-text-color: $white;
$border-color: #D0D0D0;
