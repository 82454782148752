cx-category-navigation {
  h5 {
    text-transform: none;
  }

  cx-navigation-ui {
    &.flyout {
      @include media-breakpoint-up(lg) {
        .childs[depth='2'] {
          > nav {
            &:not(:only-child):not(:last-child) {
              padding-inline-end: 40px;
            }
          }
        }

        // Show dropdowns via hover when no nav is focused
        > nav {
          > .wrapper {
            background-color: var(--cx-color-inverse);
            border: 1px solid var(--cx-color-inverse);
            padding: 42px 50px;
          }

          // first level headings will be bold in desktop
          nav > h5 {
            color: var(--cx-color-border-global);
            cursor: default;
            padding-bottom: 6px;
            font-size: 18px;

            &:hover {
              color: var(--cx-color-border-global);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  div.childs > nav > cx-generic-link > a {
    padding: 6px 0;
    color: #fff;
  }
}
