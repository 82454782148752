.field-container {
  position: relative;

  label {
    position: absolute;
    left: .75rem;
    top: .15rem;
    font-size: .8rem;
    color: $doehler-blue;
    font-family: $headline-font;
  }

  input {
    width: 100%;
    height: 55px;
    background: $very-light-grey;
    border-top: 1px solid $very-light-grey;
    border-bottom: 1px solid $grey;
    color: $doehler-blue;
    padding: .95rem .8rem 0 .8rem;
    font-size: 1.1rem;
    font-family: inherit;
    cursor: text;

    &.search-label {
      border-left: 1px solid #F4F4F4!important;
      border-right: 1px solid #F4F4F4!important;
    }

    &[type="color"] {
      padding: 0 !important;
      margin: 0;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    &.noLabel {
      padding: 0 .8rem;
    }

    &:focus {
      background: $light-grey;
      border-top: none;
      border-bottom: 2px solid $doehler-blue;
    }

    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
      color: #CC0000;
      border-bottom: 1px solid #CC0000;

      &+label {
        color: #CC0000;
      }
    }
  }

  textarea {
    width: 100%;
    min-height: 200px;
    background: $very-light-grey;
    border: none;
    border-top: 1px solid $very-light-grey;
    border-bottom: 1px solid $grey;
    flex: 1;
    padding: .8rem;
    font-size: 1.1rem;

    &:focus {
      background: $light-grey;
      border-top: none;
      border-bottom: 2px solid $doehler-blue;
    }

    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
      color: #CC0000;
      border-bottom: 1px solid #CC0000;

      &::placeholder {
        color: #CC0000;
      }
    }
  }
}

.select-container {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 17px;
    top: 20px;
    width: 17px;
    height: 17px;
    background-image: url(~src/assets/icons/down-arrow-blue.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: $doehler-blue;
    pointer-events: none;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: none;

    width: 100%;
    height: 55px;
    padding-left: .8rem;
    background: $very-light-grey;
    border-top: 1px solid $very-light-grey;
    border-bottom: 1px solid $grey;
    color: $doehler-blue;
    font-family: inherit;
    font-size: 1.1rem;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }

    option {
      color: $doehler-blue;
    }

    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
      color: #CC0000;
      border-bottom: 1px solid #CC0000;

      &::placeholder {
        color: #CC0000;
      }

      &::-ms-input-placeholder {
        color: #CC0000;
      }
    }
  }
}

.radio-group-container,
.checkbox-group-container {
  margin: .75rem .5rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

//CategoryPage sayfasındaki facet filterdaki checkboxların yapıları
.checkbox-container label {
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 1.1rem;
  line-height: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark {
      background-color: $light-grey;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $very-light-grey;
    border: 1px solid $doehler-blue;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid $doehler-blue;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input~.checkmark {
    background-color: $light-grey;
  }
}


//CategoryPage sayfasındaki facet filterdaki radio butonalrın yapıları
/*.radio-container {
  display: inline-block;
  position: relative;
  padding: 0 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background-color: $white;

      &:after {
        display: block;
      }
    }
  }

  &:hover input~.checkmark {
    background-color: $light-grey;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $very-light-grey;
    border-radius: 50%;
    border: 1px solid $doehler-blue;

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: 3px;
      left: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $mid-blue;
    }
  }
}*/
