
@media #{$mq-medium} {
  $font-size: calc(#{$base-font-size} * 1.2);
}

@media #{$mq-large} {
  $font-size: calc(#{$base-font-size} * 1.3);
}

@media #{$mq-xlarge} {
  $font-size: calc(#{$base-font-size} * 1.4);
}

@media #{$mq-xxlarge} {
  $font-size: calc(#{$base-font-size} * 1.6);
}

@mixin s {
  @media #{$mq-small} {
    @content;
  }
}

@mixin m {
  @media #{$mq-medium} {
    @content;
  }
}

@mixin l {
  @media #{$mq-large} {
    @content;
  }
}

@mixin xl {
  @media #{$mq-xlarge} {
    @content;
  }
}

@mixin xxl {
  @media #{$mq-xxlarge} {
    @content;
  }
}
