cx-page-layout.footer {
  background-color: var(--cx-color-inverse);

  cx-footer-navigation {
    a,
    h5,
    a:hover {
      color: var(--cx-color-dark);
    }
  }
}
