@mixin box-shadow($offset-x: 2px, $offset-blur: 5px) {
  -moz-box-shadow: $offset-x 0 $offset-blur 0 $box-shadow-color;
  -webkit-box-shadow: $offset-x 0 $offset-blur 0 $box-shadow-color;
  box-shadow: $offset-x 0 $offset-blur 0 $box-shadow-color;
}

@mixin flex-box($direction: row, $align-items: center, $justify-content: space-between, $wrap: wrap) {
  -ms-flex-direction: $direction;
  -ms-flex-wrap: $wrap;
  -webkit-align-items: $align-items;
  -webkit-flex-direction: $direction;
  -webkit-flex-wrap: $wrap;
  -webkit-justify-content: $justify-content;
  align-items: $align-items;
  display: flex;
  display: -ms-flexbox;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify-content;
}

@mixin transition($properties...) {
  -moz-transition: $properties;
  -o-transition: $properties;
  -webkit-transition: $properties;
  transition: $properties;
}

@mixin click-button($width: 125px) {
  @include transition(background-color .3s ease-in-out, border-color .3s ease-in-out);
  background-color: $doehler-blue;
  border: 1px solid transparent;
  color: $white;
  cursor: pointer;
  display: block;
  margin: 10px 0 10px 20px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  width: $width;

  fa-icon {
    padding: 0 0 0 8px;
    font-size: 1.35rem;
    vertical-align: middle;
  }

  &:hover {
    background-color: $white;
    color: $doehler-blue;
    border-color: $doehler-blue;
  }
}

@mixin loading-content() {
  .loading-content {
    background-color: rgba(255, 255, 255, .9);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;

    .spinner {
      left: 50%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      text-align: center;
    }

    .spinner > div {
      width: 18px;
      height: 18px;
      background-color: $doehler-blue;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
        }
    }
  }
}

@mixin line-clamp($line-count: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin scale-effect($scaleSize: .95) {
  @include transition(transform .3s ease-in-out);
  transform: scale(1) translate3d(0,0,1px);
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(1) translate3d(0,0,1px);
  &:hover {
    transform: scale($scaleSize) translate3d(0,0,0);
    -webkit-transform: scale($scaleSize) translate3d(0,0,1px);
  }
}

@mixin ellipsis($width: 100%) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}
